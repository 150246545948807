@import url(reset.css);


.header {
    margin: 2rem;

    h1 {
        font-weight: bold;
        font-style: italic;
        color: #155e75;
        font-size: 1.5rem;
    }
}

.main {
    margin: 0rem 2rem 2rem 2rem;
}


.preview-options {
    display: grid;
    column-gap: 1%;
    grid-template-columns: 59% 40%;


    .preview {
        overflow: auto;
        // background-color: yellowgreen;

        .preview-body {
            padding: 5rem;
            // background-color: blue;
            height: 40rem;
            box-sizing: border-box;
            width: auto;
            overflow: auto;
        }
    }

    .options {

        background-color: white;
        overflow: auto;

        .options-body {
            display: grid;
            grid-template-columns: 30% 69%;
            column-gap: 1%;
            padding: 1rem;
            // background-color: #568ea2;
            // min-height: 20rem;





            .option-category-btn {
                cursor: pointer;
                background-color: #94a3b8;
                padding: 0.5rem;
                color: white;
                border-style: solid;
                border-width: 0.1rem;
                // border-color: black;
                text-align: center;
                border-radius: 0.5rem;
            }

            .option-category-btn:hover {
                background-color: #aeb7c5;
            }

            .option-category-panels {
                padding: 1rem;

                .option-category-panel {

                    display: flex;
                    flex-direction: column;
                    gap: 1.5rem;

                    .title {
                        font-weight: bold;
                        font-size: 1.2rem;
                    }

                    .range-input {
                        display: inline-block;
                        margin-left: 0.5rem;
                    }

                }
            }

        }



    }
}

.code-panels {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 49% 50%;
    width: 50%;
    column-gap: 1%;

    .copy-button {
        margin: 0.5rem;
        background-color: #2658a8;
        color: white;
        padding: 0.2rem 1rem;
        border-radius: 0.3rem;
        cursor: pointer;
        font-weight: bold;
    }
}

.panel {
    border: 0.1rem;
    border-style: solid;
    box-shadow: 2px 8px 5px 0px rgba(0,0,0,0.2);

    .panel-header {
        padding: 0.3rem 0.5rem;
        background-color: #155e75;
        background-image: linear-gradient(to right top, #155e75, #2e6e84, #437e92, #568ea2, #699fb1);


        .panel-title {
            color: white;
            font-weight: bold;
            font-size: 1rem;
        }
    }
}

@media screen and (max-width: 1200px) {

    .preview-options {
        // background-color: green;

        .options {


            .options-body {
                grid-template-columns: none;
                grid-template-rows: auto auto;
            }

        }

    }
}


@media screen and (max-width: 992px) {

    .preview-options {
        .preview {
            .preview-body {
                padding: 2rem;
            }
        }
    }

    .code-panels {
        width: 100%;
    }

}

@media screen and (max-width: 600px) {


    .preview-options {
        grid-template-columns: none;
        row-gap: 1rem;

        .preview {
            .preview-body {
                padding: 2rem 1rem;
                height: 30rem;

            }
        }

    }
}

